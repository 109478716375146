import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import useLocalize from '../../../hooks/useLocalize';
import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import { renderPageSections } from '../renderPageSections';

const SubscriptionPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data

  if (!rawData || rawData.sanitySubscriptionPage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const subscriptionPageProps = {
    id: data.sanitySubscriptionPage._id,
    metadata: { ...data.sanitySubscriptionPage._rawRoute, href },
    sections: data.sanitySubscriptionPage._rawSections,
    bubbleCtaSection: data.sanitySubscriptionPage._rawBubbleCtaSection,
  };

  return (
    <Layout
      locale={locale}
      menuColor="white"
      {...subscriptionPageProps.metadata}
      sanityId={subscriptionPageProps.id}
    >
      {renderPageSections(subscriptionPageProps.sections)}
      {subscriptionPageProps.bubbleCtaSection && (
        <BubbleCtaSection {...subscriptionPageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

SubscriptionPage.propTypes = {
  data: PropTypes.shape({ sanitySubscriptionPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }),
};

SubscriptionPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: 'us' },
};

export default SubscriptionPage;

export const query = graphql`
  query($slug: String) {
    sanitySubscriptionPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
    }
  }
`;
